import React from 'react'
import './card.css'
import Card from './Card'
import card__img_1 from "../../assets/images/card-1-img.webp"
import card__img_2 from "../../assets/images/card-2-img.webp"
import card__img_3 from "../../assets/images/card-3-img.webp"

export default function CardBlock() {
  return (
    <section className='sc-cards'>
        <div className='container'>
            <div className='cards__wrap'>
                <Card 
                overlay={'#1E5449'}
                bgUrl={card__img_1}
                text={'Наши AI-менеджеры помогают управлять продажами, анализируют данные и принимают решения лучше людей.'} />
                <Card 
                overlay={'#EA8056'}
                bgUrl={card__img_2}
                text={'Идеально для любого бизнеса, который хочет увеличить продажи и экономить деньги с помощью ИИ.'} />
                <Card 
                overlay={'#000000'}
                bgUrl={card__img_3}
                text={'Клиенты получают AI-менеджера на сайт или в соцсети, что помогает увеличить конверсию клиентов и улучшить продажи.'} />
            </div>
        </div>
    </section>
  )
}
