import React, { useState } from 'react';
import './feedbackForm.css';

export default function FeedbackForm() {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  return (
    <section className="sc-feedback">
      <div className="container">
        <div className="head__info">
          <h1>Платформа для отзывов</h1>
          <div className="stars">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= (hover || rating) ? "on" : "off"}
                  onClick={() => setRating(index)}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(rating)}
                >
                  <span className="star">&#9733;</span>
                </button>
              );
            })}
          </div>
          <div className="form__group">
            <input type="text" placeholder="Ваш отзыв" className="form__input" />
          </div>
          <div className="form__group">
            <input type="text" placeholder="Ваша ссылка" className="form__input" />
          </div>
          <button className="submit__button">Отправить</button>
        </div>
      </div>
    </section>
  );
}
