import React from 'react';
import './banner.css';

export default function Banner() {
  return (
    <section className='sc-banner'>
      <div className='container'>
        <div className='head__info'>
          <h1>Революция в сфере продаж с помощью менеджеров на базе Искусственного интеллекта</h1>
        </div>
      </div>
    </section>
  );
}
