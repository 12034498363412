import React, { useState, useEffect } from 'react';
import './testing.css';
import Image from '../image/Image';
import icon__shop from '../../assets/images/shop.png';
import icon__automobile from '../../assets/images/automobile.png';
import icon__salon from '../../assets/images/salon.png';
import icon__jim from '../../assets/images/jim.png';
import icon__nedvijimost from '../../assets/images/nedvijimost.png';
import icon__bank from '../../assets/images/bank.png';
import sendIcon from '../../assets/images/send_button.png';

const UUID_STORAGE_KEY = 'uniqueDeviceId';
const UUID_EXPIRY_KEY = 'uniqueDeviceIdExpiry';
const UUID_LIFETIME = 30 * 24 * 60 * 60 * 1000;

export default function TestingBlock() {
  const [status, setStatus] = useState('Статус');
  const [select, setSelect] = useState('Выберите сферу продаж для тестирования ИИ');
  const [selectedImage, setSelectedImage] = useState();
  const [inputValue, setInputValue] = useState('');
  const [clientUUID, setClientUUID] = useState('');
  const [messages, setMessages] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const domain = window.location.hostname;

  const options = [
    { icon: icon__shop, optionText: 'Менеджер в сфере розничной торговли' },
    { icon: icon__automobile, optionText: 'Менеджер в автомобильном дилерском центре' },
    { icon: icon__salon, optionText: 'Менеджер в салоне красоты' },
    { icon: icon__jim, optionText: 'Менеджер в фитнес клубе' },
    { icon: icon__nedvijimost, optionText: 'Менеджер в агентстве недвижимости' },
    { icon: icon__bank, optionText: 'Менеджер в банковской сфере' },
  ];

  const [selectWrapClassActive, setSelectWrapClassActive] = useState(false);

  const generateUUID = () => {
    var d = new Date().getTime();
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  useEffect(() => {
    let uniqueId = localStorage.getItem(UUID_STORAGE_KEY);
    let expiryTime = localStorage.getItem(UUID_EXPIRY_KEY);
    const now = new Date().getTime();

    if (!uniqueId || !expiryTime || now > expiryTime) {
      uniqueId = generateUUID();
      localStorage.setItem(UUID_STORAGE_KEY, uniqueId);
      localStorage.setItem(UUID_EXPIRY_KEY, now + UUID_LIFETIME);
    }
    setClientUUID(uniqueId);
  }, []);

  useEffect(() => {
    if (clientUUID && select !== 'Выберите роль ИИ') {
      const interval = setInterval(() => {
        checkForUpdates();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [clientUUID, select]);

  const checkForUpdates = async () => {
    try {
      const response = await fetch(`https://heliosai.ru/api/last_update?ip=${clientUUID}&table=${encodeURIComponent(select)}&domain=heliosai`);
      if (response.ok) {
        const data = await response.json();
        if (data.last_modified !== lastUpdate) {
          setLastUpdate(data.last_modified);
          fetchMessages();
        }
      } else {
        console.error('Failed to check for updates');
      }
    } catch (error) {
      console.error('Error checking for updates:', error.message || error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await fetch(`https://heliosai.ru/api/messagesH?ip=${clientUUID}&table=${encodeURIComponent(select)}&domain=${encodeURIComponent(domain)}`);
      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error.message || error);
      setMessages([]);
    }
  };

  const handleToggleSelect = () => {
    setSelectWrapClassActive(!selectWrapClassActive);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      role: select,
      query: inputValue,
      ip: clientUUID,
      siteKey: domain,
    };
    setInputValue('');

    try {
      const response = await fetch('https://heliosai.ru/api/dataH', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      console.log('Success:', result);
      fetchMessages();
    } catch (error) {
      console.error('Error:', error.message || error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const displayedMessages = messages.slice(0, Math.ceil(messages.length / 1));

  return (
    <section className='sc-testing'>
      <div className='title'>
        <div className='container'>
          <h2>Протестируйте работу ИИ-менеджера в различных сферах продаж</h2>
        </div>
      </div>
      <div className='container'>
        <div className='test__form'>
          <div className='form__box'>
            <form onSubmit={handleSubmit}>
              <div className='form__head'>
                <div className={`select_wrap ${selectWrapClassActive ? 'active' : ''}`}>
                  <ul className="default_option">
                    <li onClick={() => handleToggleSelect()}>
                      <div className="option pizza">
                        <div className="icon"><Image img__src={selectedImage} /></div>
                        <p>{select}</p>
                      </div>
                    </li>
                  </ul>
                  <ul className="select_ul">
                    {options.map((o, index) => {
                      return (
                        <li key={index} onClick={() => { setSelect(o.optionText); setSelectedImage(o.icon); setSelectWrapClassActive(false) }}>
                          <div className="option">
                            <div className="icon"> <Image img__src={o.icon} /> </div>
                            <p>{o.optionText}</p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div className='form__body'>
                <div className='messages'>
                  {displayedMessages.map((msg, index) => (
                    <div key={index} className={`message ${index % 2 === 0 ? 'right' : 'left'}`}>
                      <span className='text'>{msg.text}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className='form__bottom'>
                <div className='field'>
                  <input
                    type='text'
                    placeholder='Введите запрос'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <button type='submit'>
                    <img src={sendIcon} alt="Отправить" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
