import React, { useEffect } from 'react';
import './info.css';
import robotImg_1 from '../../assets/images/robot-1-img.webp';
import robotImg_2 from '../../assets/images/robot-2-img.webp';

import Image from '../image/Image';

export default function InfoBlock() {
  useEffect(() => {
    const sections = document.querySelectorAll('.info__wrap');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    sections.forEach(section => {
      observer.observe(section);
    });
  }, []);

  return (
    <section className='sc-info'>
      <div className='container'>
        <div className='info__title'>
          Наш обучающийся AI-менеджер интегрируется на ваш сайт и в социальные сети, чтобы улучшить продажи. Он помогает обрабатывать заказы, отвечать на вопросы клиентов и анализировать данные для принятия лучших решений, увеличивая количество покупок и улучшая клиентский опыт.
        </div>
        <div className='info__wrap img__left'>
          <div className='img__box'>
            <Image img__src={robotImg_1} />
          </div>
          <div className='info__text'>
            <h4>Будьте лучше конкурентов с помощью наших умных решений</h4>
            <ul>
              <li><strong>Проблемы менеджеров по продажам:</strong> Необходимость выплачивать заработную плату и предоставлять социальный пакет, а также ограничение обработки заявок только в рабочее время.</li>
              <li><strong>Зависимость от состояния:</strong> Качество обработки заявок зависит от психо-эмоционального состояния менеджера, что требует постоянного контроля качества обслуживания и увеличивает затраты на управление персоналом.</li>
              <li><strong>Человеческий фактор:</strong> «Человеческий фактор» продолжает быть причиной различных ошибок при обработке заявок клиентов.</li>
            </ul>

          </div>
        </div>
        <div className='info__wrap img__right'>
          <div className='img__box'>
            <Image img__src={robotImg_2}/>
          </div>
          <div className='info__text'>
            <h4>Автоматизируйте задачи для максимальной эффективности</h4>
            <ul>
              <li><strong>Индивидуальный подход:</strong> Наш ИИ-менеджер создается на основе анализа информации о вашей
                компании, включая прайс-листы и историю продаж.
              </li>
              <li><strong>Интеграция:</strong> Он интегрируется на ваш сайт в виде виджета и в социальные сети для автоматической обработки заявок и взаимодействия с клиентами.</li>
              <li><strong>Функциональность:</strong> ИИ может отправлять горячие лиды через Telegram-бота и мгновенно отвечать на запросы клиентов, работая круглосуточно без перерывов.</li>
              <li><strong>Естественное общение:</strong> Его стиль общения настроен таким образом, чтобы быть максимально похожим на человеческий, что подтверждено международными исследованиями.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
