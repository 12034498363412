import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';  // Импортируем useParams для получения параметра из URL
import './dialogues.css';

const Dialogues = () => {
  const { source } = useParams();  // Получаем параметр source из URL
  const [databases, setDatabases] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const fetchDatabases = async () => {
      const username = localStorage.getItem('username');
      if (!username) {
        console.error('No username found in localStorage');
        return;
      }

      try {
        const response = await axios.get(`https://heliosai.ru/api/databases`, {
          params: { username, source }  // Передаем параметр source в запрос
        });
        setDatabases(response.data.databases);
      } catch (error) {
        console.error('Error fetching databases:', error);
      }
    };

    fetchDatabases();
  }, [source]);

  const fetchMessages = async (database) => {
    const username = localStorage.getItem('username');
    if (!username) {
      console.error('No username found in localStorage');
      return;
    }

    try {
      const response = await axios.get(`https://heliosai.ru/api/dialogue_messages`, {
        params: { dbFile: database, username, source }  // Передаем параметр source в запрос
      });
      setMessages(response.data.messages);
      setSelectedDatabase(database);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    const username = localStorage.getItem('username');
    if (!username || !selectedDatabase) {
      console.error('No username or database selected');
      return;
    }

    try {
      const response = await axios.post('https://heliosai.ru/api/dataD', {
        role: 'assistant',
        query: newMessage,
        ip: selectedDatabase,
        siteKey: username,
        source: source  // Передаем параметр source в запрос
      });

      if (response.data && response.data.status === 'success') {
        setMessages([...messages, { author: 'assistant', text: newMessage }]);
        setNewMessage('');
      } else {
        console.error('Error sending message:', response.data ? response.data.message : 'No response data');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="dialogues-container">
      <div className="dialogues-list">
        <h2>Выберите диалог</h2>
        {databases.map((database, index) => (
          <div
            key={index}
            className={`database-item ${selectedDatabase === database ? 'selected' : ''}`}
            onClick={() => fetchMessages(database)}
          >
            {database}
          </div>
        ))}
      </div>
      <div className="messages-container">
        <h2>Диалог</h2>
        <div className="messages">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.author}`}>
              <p><strong>{msg.author}</strong>: {msg.text}</p>
            </div>
          ))}
        </div>
        {selectedDatabase && (
          <form onSubmit={handleSendMessage} className="send-message-form">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Введите сообщение"
              required
            />
            <button type="submit">Отправить</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Dialogues;
