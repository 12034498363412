import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';

export default function Header() {
  return (
    <header className='header'>
      <div className='container'>
        <div className='logo'>Helios</div>
        <nav className='nav-links'>
          <Link to="/register">Зарегистрироваться</Link>
          <Link to="/login">Войти</Link>
        </nav>
      </div>
    </header>
  );
}
