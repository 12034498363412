import React from 'react'
import './writeUs.css'

export default function WriteUsBlock() {
  return (
    <section className='sc-write-us'>
        <div className='container'>
          <div className='write__info'>
              <h2>Запустите ИИ Менеджера сегодня.</h2>
              <a className='primary--btn' href="https://t.me/heliosai_official_bot">Написать нам</a>
          </div>
        </div>
    </section>
  )
}
