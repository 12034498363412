import React, { useState, useEffect, useRef } from 'react';
import './form.css';
import sendIcon from '../../assets/images/кнопка_Монтажная область 1.png';
import linkHel from '../../assets/images/Бизнес_мессенджер_Монтажная_область_1_копия_4.png';

const UUID_STORAGE_KEY = 'uniqueDeviceId';
const UUID_EXPIRY_KEY = 'uniqueDeviceIdExpiry';
const UUID_LIFETIME = 30 * 24 * 60 * 60 * 1000;

export default function FormBlock() {
  const [inputValue, setInputValue] = useState('');
  const [clientUUID, setClientUUID] = useState('');
  const [messages, setMessages] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(true);
  const messagesEndRef = useRef(null);

  const generateUUID = () => {
    var d = new Date().getTime();
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  useEffect(() => {
    let uniqueId = localStorage.getItem(UUID_STORAGE_KEY);
    let expiryTime = localStorage.getItem(UUID_EXPIRY_KEY);
    const now = new Date().getTime();

    if (!uniqueId || !expiryTime || now > expiryTime) {
      uniqueId = generateUUID();
      localStorage.setItem(UUID_STORAGE_KEY, uniqueId);
      localStorage.setItem(UUID_EXPIRY_KEY, now + UUID_LIFETIME);
    }
    setClientUUID(uniqueId);
  }, []);

  useEffect(() => {
    if (clientUUID) {
      const interval = setInterval(() => {
        checkForUpdates();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [clientUUID]);

  const checkForUpdates = async () => {
    try {
      const response = await fetch(`https://heliosai.ru/api/last_updateF?ip=${clientUUID}&table=HeliosAI&domain=heliosai`);
      if (response.ok) {
        const data = await response.json();
        if (data.last_modified !== lastUpdate) {
          setLastUpdate(data.last_modified);
          fetchMessages();
        }
      }
    } catch (error) {
      console.error('Failed to check for updates', error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await fetch(`https://heliosai.ru/api/messages?ip=${clientUUID}&table=HeliosAI&domain=heliosai&source=site`);
      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages);
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error.message || error);
      setMessages([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      role: 'HeliosAI',
      query: inputValue,
      ip: clientUUID,
      siteKey: 'heliosai',
      source: 'site'
    };
    setInputValue('');

    try {
      const response = await fetch('https://heliosai.ru/api/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      console.log('Success:', result);
      fetchMessages();
    } catch (error) {
      console.error('Error:', error.message || error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handleFormTopClick = () => {
    setFormVisible(false);
    setButtonVisible(true);
  };

  const handleFormClick = () => {
    setFormVisible(true);
    setButtonVisible(false);
  };

  return (
    <>
      {buttonVisible && (
        <button onClick={handleFormClick} style={{ position: 'fixed', bottom: '0px', right: '0px' }} id="openA">
          <div className='form__send__top__open'>
            <div className='logo' >Напишите нам</div>
          </div>
        </button>
      )}
      <section className={`sc-form ${formVisible ? 'visible' : ''}`} style={{ display: formVisible ? 'block' : 'none' }}>
        <div className='container'>
          <div className='test__form'>
            <div className='form__box'>
              <form onSubmit={handleSubmit}>
                <div className='form_send__top' onClick={handleFormTopClick}>
                  <div className='logo'>Напишите нам</div>
                </div>
                <div className='form__body'>
                  <div className='messages'>
                    <a href="https://heliosai.ru"><img src={linkHel} alt="" style={{ width: "80%" }} /></a>
                    {messages.map((msg, index) => (
                      <div key={index} className={`message ${msg.author === 'user' ? 'right' : 'left'}`}>
                        <span className='text'>{msg.text}</span>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                </div>
                <div className='form__bottom'>
                  <div className='field'>
                    <input
                      type='text'
                      placeholder='Введите запрос'
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    <button type='submit'>
                      <img src={sendIcon} alt="Отправить" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}