import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './dashboard.css';

const Dashboard = () => {
  const [data, setData] = useState({
    instagram_login: '',
    instagram_password: '',
    vk: '',
    whatsapp: '',
    telegram: '',
  });

  const [editData, setEditData] = useState({
    instagram_login: '',
    instagram_password: '',
    vk: '',
    whatsapp: '',
    telegram: '',
  });

  const [visibleSection, setVisibleSection] = useState(null);

  const username = localStorage.getItem('username');

  useEffect(() => {
    const fetchDatabases = async () => {
      if (!username) {
        console.error('No username found in localStorage');
        return;
      }

      try {
        const response = await axios.get(`https://heliosai.ru/api/get_client_data`, {
          params: { username }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDatabases();

    const sections = document.querySelectorAll('.section');

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    });

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      sections.forEach(section => {
        observer.unobserve(section);
      });
    };
  }, [username]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleUpdate = (field) => {
    fetch(`https://heliosai.ru/api/update_${field}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ value: editData[field], domen: username }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'success') {
          setData({ ...data, [field]: editData[field] });
          alert('Данные успешно обновлены');
        } else {
          alert('Ошибка обновления данных');
        }
      })
      .catch((error) => console.error('Ошибка обновления данных:', error));
  };

  const handleUpdateInstagram = () => {
    fetch(`https://heliosai.ru/api/update_instagram`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        instagram_login: editData.instagram_login,
        instagram_password: editData.instagram_password,
        domen: username,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'success') {
          setData({
            ...data,
            instagram_login: editData.instagram_login,
            instagram_password: editData.instagram_password,
          });
          alert('Данные Instagram успешно обновлены');
        } else {
          alert('Ошибка обновления данных');
        }
      })
      .catch((error) => console.error('Ошибка обновления данных:', error));
  };

  const toggleSection = (section) => {
    setVisibleSection(visibleSection === section ? null : section);
  };

  return (
    <div className="dashboard">
      <h2>Добро пожаловать в админ панель</h2>

      <div className="section">
        <h3>Интеграция с сайтом</h3>
        <p>Для интеграции на ваш сайт вставьте следующий код:</p>
        <div className="integration-code">
          <input
            type="text"
            value={`<script src="https://heliosai.ru/widget/widget.js" data-sitekey="${username}"></script>`}
            readOnly
            className="input-field"
            onClick={(e) => e.target.select()} // Выделение текста для удобного копирования
          />
        </div>
        <button className="link-button" onClick={() => window.location.href='/clients/site'}>Перейти к диалогам на сайте</button>
      </div>

      <div className="section">
        <h3>Instagram</h3>
        <label htmlFor="instagram_login">Введите логин Instagram:</label>
        <input
          type="text"
          id="instagram_login"
          name="instagram_login"
          value={editData.instagram_login}
          onChange={handleChange}
          placeholder={data.instagram_login}
          className="input-field"
        />
        <label htmlFor="instagram_password">Введите пароль Instagram:</label>
        <input
          type="text"
          id="instagram_password"
          name="instagram_password"
          value={editData.instagram_password}
          onChange={handleChange}
          placeholder={data.instagram_password}
          className="input-field"
        />
        <button onClick={handleUpdateInstagram} className="update-button">Изменить Instagram</button>
        <button className="toggle-button" onClick={() => toggleSection('instagram')}>
          {visibleSection === 'instagram' ? 'Скрыть' : 'Показать'} инструкцию
        </button>
        {visibleSection === 'instagram' && (
          <div className="info-content">
            <p>Чтобы получить доступ к Instagram, перейдите в настройки аккаунта и сгенерируйте пароль для приложения.</p>
          </div>
        )}
        <button className="link-button" onClick={() => window.location.href='/clients/instagram'}>Перейти к Instagram</button>
      </div>

      <div className="section">
        <h3>ВКонтакте</h3>
        <label htmlFor="vk">Введите API-ключ сообщества ВКонтакте:</label>
        <input
          type="text"
          id="vk"
          name="vk"
          value={editData.vk}
          onChange={handleChange}
          placeholder={data.vk}
          className="input-field"
        />
        <button onClick={() => handleUpdate('vk')} className="update-button">Изменить</button>
        <button className="toggle-button" onClick={() => toggleSection('vk')}>
          {visibleSection === 'vk' ? 'Скрыть' : 'Показать'} инструкцию
        </button>
        {visibleSection === 'vk' && (
          <div className="info-content">
            <p>Чтобы получить API-ключ сообщества ВКонтакте:</p>
            <ol>
              <li>Зайдите в управление вашим сообществом.</li>
              <li>Перейдите в раздел «Работа с API».</li>
              <li>Нажмите на кнопку «Создать ключ».</li>
            </ol>
            <p>После создания ключа, скопируйте его и вставьте в поле выше.</p>
          </div>
        )}
        <button className="link-button" onClick={() => window.location.href='/clients/vk'}>Перейти к ВКонтакте</button>
      </div>

      <div className="section">
        <h3>Telegram</h3>
        <label htmlFor="telegram">Введите номер телефона Telegram:</label>
        <input
          type="text"
          id="telegram"
          name="telegram"
          value={editData.telegram}
          onChange={handleChange}
          placeholder={data.telegram}
          className="input-field"
        />
        <button onClick={() => handleUpdate('telegram')} className="update-button">Изменить</button>
        <button className="toggle-button" onClick={() => toggleSection('telegram')}>
          {visibleSection === 'telegram' ? 'Скрыть' : 'Показать'} инструкцию
        </button>
        {visibleSection === 'telegram' && (
          <div className="info-content">
            <p>Введите номер телефона, связанный с вашим аккаунтом Telegram. Номер должен быть в формате +79991234567.</p>
          </div>
        )}
        <button className="link-button" onClick={() => window.location.href='/clients/telegram'}>Перейти к Telegram</button>
      </div>

      <div className="section">
        <h3>WhatsApp</h3>
        <label htmlFor="whatsapp">Введите токен из Whapi.cloud:</label>
        <input
          type="text"
          id="whatsapp"
          name="whatsapp"
          value={editData.whatsapp}
          onChange={handleChange}
          placeholder={data.whatsapp}
          className="input-field"
        />
        <button onClick={() => handleUpdate('whatsapp')} className="update-button">Изменить</button>
        <button className="toggle-button" onClick={() => toggleSection('whatsapp')}>
          {visibleSection === 'whatsapp' ? 'Скрыть' : 'Показать'} инструкцию
        </button>
        {visibleSection === 'whatsapp' && (
          <div className="info-content">
            <p>Для интеграции с WhatsApp через сервис Whapi```javascript
            .cloud:</p>
            <ol>
              <li><a href="https://whapi.cloud" target="_blank" rel="noopener noreferrer">Зарегистрируйтесь на Whapi.cloud</a> и создайте канал.</li>
              <li>Вы получите 5 дней бесплатного использования, затем подписка составит 600 рублей в месяц.</li>
              <li>Создайте канал и скопируйте полученный токен.</li>
              <li>Перейдите в настройки в правом верхнем углу и вставьте токен в поле выше.</li>
              <li>Настройте вебхуки, используя следующую ссылку:</li>
            </ol>
            <div className="webhook-url">
              <input
                type="text"
                value={`https://heliosai.ru/webhook/${username}`}
                readOnly
                className="input-field"
                onClick={(e) => e.target.select()} // Выделение текста для удобного копирования
              />
            </div>
          </div>
        )}
        <button className="link-button" onClick={() => window.location.href='/clients/whatsapp'}>Перейти к WhatsApp</button>
      </div>
    </div>
  );
};

export default Dashboard;
