import React, { useEffect } from 'react';
import './clientsSection.css';
import GM from './../../assets/images/logo.png';
import Uni from './../../assets/images/uniclinic.png';

const clients = [
  {
    name: "Клиника МГУ",
    imgSrc: Uni,
    link: "https://uniclinic.pro/"
  },
  {
    name: "Гарманова Татьяна",
    imgSrc: GM,
    link: "https://garmanovasurgery.ru/"
  }
];

export default function ClientsSection() {
  useEffect(() => {
    const cards = document.querySelectorAll('.client-card');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    cards.forEach(card => {
      observer.observe(card);
    });
  }, []);

  return (
    <section className="sc-clients">
      <div className="container">
        <h2>Наши клиенты</h2>
        <div className="clients-grid">
          {clients.map((client, index) => (
            <div key={index} className="client-card">
              <a href={client.link} target="_blank" rel="noopener noreferrer">
                <img src={client.imgSrc} alt={client.name} />
              </a>
              <p>{client.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
